import React from "react"
import { Link } from "gatsby"

import { routes } from "../utils/routes"
import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const FAQs: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2024/02/faqs-header.jpg"
          className="absolute inset-0 -z-[1]"
          alt="FAQs"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase !leading-tight">
            Frequently Asked Questions
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="FAQs" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <p>
            <strong>
              How can I give a Gift?
            </strong>
          </p>
          <div className="grid grid-cols-3 gap-6">
            <p>Online:</p>
            <p className="col-span-2">To make a secure donation now, click <Link to={routes.donate()} className="link underline hover:no-underline">here</Link></p>

            <p>By phone:</p>
            <p className="col-span-2">+44 (0) 2890 240461<br /><span className="text-sm italic mt-1 block">Phonelines are open weekdays, 8:15am - 4:30pm during term time. Hours vary during holiday periods.</span></p>

            <p>By post:</p>
            <p className="col-span-2">You can send a one-off gift or set up a regular gift by downloading a <a
                className="link underline hover:no-underline"
                href="/docs/donate-form-0323.pdf"
                target="_blank"
              >donation form here</a> and returning to the address below. For one-off gifts, all cheques should be payable to ‘The RBAI Foundation’.</p>
          </div>
          <hr className="my-5" />

          <p>
            <strong>Why are school fees not enough to fund bursaries and capital developments?</strong>
          </p>
          <p>RBAI is a category B Grammar school. We receive funding towards teaching and learning, however we do not receive any capital support towards the upkeep of the School buildings, classrooms and estate.</p>

          <p>The fees paid by parents maintain the upkeep of the buildings and school environment. They also resource an enviable range of co-curricular activities available to all of the pupils; these include cultural, sports and academic.</p>

          <p>New developments in the estate require additional funding and borrowing hence the Excellence For All capital campaign (2018-2022) which raised more than £2.8m.</p>

          <p>Over the past decade, the Board of Governors have invested more than £9m in the restoration of the main school “Soane” building, the North Wing (which houses history, religious education and politics), whilst transforming the old Technology building into a Centre of Innovation.</p>

          <p>Most recently all Science laboratories and the Modern Languages classrooms have been extensively renovated. Upgrades at Inchmarlo Preparatory Department include a new all weather playground and ICT suite.</p>

          <p>Each year the Board of Governors provide means tested bursaries for upto 10% of the pupil population, the majority of which are funded through donations received by the RBAI Foundation.</p>

          <p>Philanthropy is a critical element of the School’s development plan.</p>
          <hr className="my-5" />

          <p>
            <strong>
              Why not increase fees to fund new facilities?
            </strong>
          </p>
          <p>
            RBAI is proud to offer boys a first class education and a vast range of co-curricular activities on a par with many public schools in Great Britain, however the Board of Governors recognise that increasing fees to finance capital development would put RBAI out of reach for many families and negatively impact the School’s ethos.
          </p>
          <hr className="my-5" />

          <p>
            <strong>
              I can only give a modest amount; will my donation make any difference?
            </strong>
          </p>
          <p>
            Support from right across the Instonian community is vital in order for us to fulfil our fundraising goals.
          </p>

          <p>Making a modest regular gift such as £10 per month, is worth £450 to the School after 3 years if the donor is a UK tax payer (£10 x 36months + 25% gift aid).  </p>

          <p>A regular gift of £40 per month over 5 years will be worth £3,000 to the School, and have a remarkable impact for our pupils.   </p>

          <p>Click <Link className="link underline hover:no-underline" to={routes.donate()}>here</Link> to sign up a direct debit donation and start your support now.</p>

          <hr className="my-5" />

          <p>
            <strong>
              Is donating by credit or debit card secure?
            </strong>
          </p>
          <p>
            Yes, RBAI utilises a secure, off-site payment processor called Stripe to process one-off donations and GoCardless to process regular (recurring) direct debit donations.
          </p>
          <hr className="my-5" />

          <p>
            <strong>
              Can RBAI claim Gift Aid on my donation?
            </strong>
          </p>
          <p>
            Yes! The Royal Belfast Academical Institution is registered with the Charity Commission for Northern Ireland (Charity Number 108024).
          </p>
          <p>So long as you have paid as much Income Tax and/or Capital Gains tax as the amount of Gift Aid claimed on all of your donations in that tax year, the school can reclaim the basic tax rate (25%) on your gift, with no extra cost to you. So your donation of £100 will actually be worth £125.</p>
          <hr className="my-5" />

          <p>
            <strong>
            Will my donation be tax-deductible?
            </strong>
          </p>
          <p>
            UK: If you’re in the UK and eligible for Gift Aid, RBAI can claim 25% back in tax from your donation. If you’re a Higher Rate Tax Payer (40% and above), you can claim tax back on your charitable donations at the end of the tax year.
          </p>
          <p>
            US & Canada: Donors in the US can give to the School via the British Schools & Universities Foundation, ensuring they receive a 501c gift receipt; click <a href="https://www.bsuf.org" target="_blank" className="link underline hover:no-underline" rel="noopener noreferer">here</a>. Canadian alumni can benefit from a similar arrangement with CAF Canada; click <a href="https://www.cafcanada.ca" target="_blank" className="link underline hover:no-underline" rel="noopener noreferer">here</a>.
          </p>
          <hr className="my-5" />

          <p>
            <strong>
            Is there a minimum or maximum donation I can make?
            </strong>
          </p>
          <p>
            The minimum online gift amount is £1. There’s no maximum donation, and no limit on the impact you can make.
          </p>
          <hr className="my-5" />

          <p>
            <strong>
            How can I get in touch with the RBAI Foundation?
            </strong>
          </p>
          <p>
          Please feel free to email any questions to the RBAI Foundation Office via <a href="mailto:p.anderson@rbai.org.uk" className="link underline hover:no-underline" rel="noopener noreferer">p.anderson@rbai.org.uk</a> or call us on +44 (0)2890 240461.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default FAQs
